@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap);
html {
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
  padding: 0;
  margin: 0;
}

.app {
  width: 960px;
  margin: 0 auto;
  padding: 20px;
}

nav ul {
  list-style: none;
  display: flex;
  background-color: black;
  margin-bottom: 20px;
}

nav ul li {
  padding: 20px;
}

nav ul li a {
  color: white;
  text-decoration: none;
}

.current {
  border-bottom: 4px solid white;
}

h1 {
  margin-top: 20px;
  margin-bottom: 20px;
}

p {
  margin-top: 20px;
  margin-bottom: 15px;
}


/*
@media (max-width: 768px) {
  /* Styles for screens with a width of 768px or less 
}
*/
/*
@media (min-width: 769px) and (max-width: 1024px) {
   Styles for screens between 769px and 1024px wide 
}
*/
.navbar {
    display: flex;
    height: 60px;
    justify-content: space-between;
    align-items: center;
    background-color: #02120F;
    padding: 0px 30px;
    color: white;
    margin-top: 0;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000;
    font-family: 'Montserrat', sans-serif;
  }
  .logo-image {
    height: 55px; /* Adjust the size as needed */
    margin-left: 5px; /* Add some space between the text and the image */
    width: auto;
    vertical-align: middle; /* Align the image with the text */
  }
  /*.logo a {
    font-size: 32px;
    /*font-family: 'Lato', sans-serif;
    text-decoration: none;
    color: white;
    position: relative;
    top: 14px;
 /*   border: 2px solid white; /* Border width and color */
    /*padding: 5px 10px; /* Padding inside the border */
    /*display: inline-block; /* Allows padding and border to be applied */
    /*transition: 0.3s; /* Optional: Smooth transition effect on hover */
    /*border-radius: 4px;
  }
*/


  /* Optional: Change border color on hover */
  .logo a:hover {
    border-color: #f9a825; /* Change to your preferred hover color */
  }
  
  .menu {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    background-color: #02120F;
  }
  
  .menu li {
    margin: 0 15px;
  }
  
  .menu a {
    text-decoration: none;
    color: white;
    transition: color 0.3s;
  }
  
  .menu a:hover {
    color: #00886a;
  }
  
@media (max-width: 768px) {
  /* Styles for screens with a width of 768px or less */
}

@media (min-width: 769px) and (max-width: 1024px) {
  /* Styles for screens between 769px and 1024px wide */
}
.front-page {
    position: relative;
    width: 100%;
    overflow: hidden;
    font-family: 'Montserrat', sans-serif;
    text-align: left;
  }
  .backdrop {
    background-image: url(/static/media/backdrop_upscaled_desktop_resolution.62e928a7.png);
    min-height: 60vh;
    background-size: cover; /* Scale the background image to cover the entire container */
    background-repeat: no-repeat; /* Prevent the background image from repeating */
    background-position: center; /* Center the background image */
    text-align: left;
  }
  .content {
    resize: both; /* allows resizing both vertically and horizontally */
    min-width: 1000px;
    max-width: 100%;
    min-height: 50px;
    max-height: 100%;
    position: absolute;
    top: 47%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    font-size: 28px;
    color: #fff; /* Change text color as needed */
    margin: 20px;
  }

  .btn {
    background-color: #208771; 
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px; 
    padding: 10px 20px; 
    border: none; 
    border-radius: 5px; 
    cursor: pointer; 
    transition: background-color 0.3s, color 0.3s;
  }
  .btn:hover{
    background-color: #186c5d; 
  }
  .btn:active{
    background-color: #145c4f;
  }

  .btn-alt {
    background-color: rgba(2,18,15,.9);
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px; 
    padding: 10px 20px; 
    border: none; 
    border-radius: 5px; 
    cursor: pointer; 
    transition: background-color 0.3s, color 0.3s;
  }
  .btn-alt:hover{
    background-color: #186c5d; 
  }
  .btn-alt:active{
    background-color: #145c4f;
  }
  .align-left{
    text-align: left;
    letter-spacing: -1px; /* You can adjust the value as needed */
  }
  .align-right{
    float: right;
    position: relative;
    top: -300px;
  }
  .css-bnr459-UppercaseOneLiner {
    color: #D5D0D7;
    font-family: 'Inter',sans-serif;
    font-weight: 400;
    font-size: clamp( 1rem * 0.8, 1vw, 1rem * 0.9 );
    text-transform: uppercase;
    font-weight: 600;
    text-align: left;
}
.img-nyse{
  float: left;
  height: 50px;
  padding: 3px;
}

/* TextBoxOverlay.css */
.text-box-overlay {
    background-color: rgba(2, 18, 15, 0.9); /* White background with 70% opacity 02120F*/
    /* padding: 10%; */
    padding-left:5%;
    padding-bottom:5%;
    padding-right:5%;
    padding-top:2%;
    border-radius: 5%;
    max-width: 150%; /* Adjust as needed */
    position: static;
    height: auto;
    /*margin: 20px auto;  Center the box */
    text-align: center; /* Center the text */

  }

:root{
    --info_width: 350px

}
.gradient-img{
    background-image: url(/static/media/darker_gradient_desktop.456e6b73.png);
    min-height: 100vh;
    background-size: cover; /* Scale the background image to cover the entire container */
    background-repeat: no-repeat; /* Prevent the background image from repeating */
    background-position: center; /* Center the background image */
    
  }
  .product-info{
    position: absolute;
    top: 68%;
    left: 46.1%;
    font-family: 'Montserrat', sans-serif;
    color: #fff;
    
  }
  .product-line{

    margin-top: 35px;
    width: 100%;
    margin-bottom: 25px;
  }
  .product-header{
    position: relative;
    left: -23%;

  }
  .product-info-flex{
    position: relative;
    display: flex;
    justify-content: center;

    max-width: 230px;


  }
.heading-1{
    margin-right: 70px;

}
.heading-2{
  margin-right: 70px;

}

  .stock-image{
    margin-top: 25px;
    margin-bottom: 25px;
  }
    /*
  .heading-mid{
    position: absolute;
    top: 70%;
    left: 45%;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    text-align: left;
    max-width: var(--info_width);
  }
  .text-stocks-top{
    margin-bottom: 8px;
    letter-spacing: -1px; /* You can adjust the value as needed 
  }
  .text-stocks{
    margin-top: 8px;
  }
  .heading-left{
    position: absolute;
    top: 70%;
    left: 25%;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    text-align: left;
    max-width: var(--info_width);
    min-width: var(--info_width);
  }
  */
/* styles.css or Footer.css */
/* .app-footer {
  background-image: url('./images/footer.png');
  min-height: 25vh;
  background-size: cover; /* Scale the background image to cover the entire container 
  background-repeat: no-repeat; /* Prevent the background image from repeating 
  background-position: center; /* Center the background image 
  } */
  .app-footer{
    text-align: center;
    background-color: black;
    color:white;
    padding: 3px;
    padding-top: 200px;
    font-family: 'Montserrat', sans-serif;
  }
@media (max-width: 768px) {
  /* Styles for screens with a width of 768px or less */
}

@media (min-width: 769px) and (max-width: 1024px) {
  /* Styles for screens between 769px and 1024px wide */
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

