/* styles.css or Footer.css */
/* .app-footer {
  background-image: url('./images/footer.png');
  min-height: 25vh;
  background-size: cover; /* Scale the background image to cover the entire container 
  background-repeat: no-repeat; /* Prevent the background image from repeating 
  background-position: center; /* Center the background image 
  } */
  .app-footer{
    text-align: center;
    background-color: black;
    color:white;
    padding: 3px;
    padding-top: 200px;
    font-family: 'Montserrat', sans-serif;
  }