@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
@media (max-width: 768px) {
  /* Styles for screens with a width of 768px or less */
}

@media (min-width: 769px) and (max-width: 1024px) {
  /* Styles for screens between 769px and 1024px wide */
}
.front-page {
    position: relative;
    width: 100%;
    overflow: hidden;
    font-family: 'Montserrat', sans-serif;
    text-align: left;
  }
  .backdrop {
    background-image: url('./images/backdrop_upscaled_desktop_resolution.png');
    min-height: 60vh;
    background-size: cover; /* Scale the background image to cover the entire container */
    background-repeat: no-repeat; /* Prevent the background image from repeating */
    background-position: center; /* Center the background image */
    text-align: left;
  }
  .content {
    resize: both; /* allows resizing both vertically and horizontally */
    min-width: 1000px;
    max-width: 100%;
    min-height: 50px;
    max-height: 100%;
    position: absolute;
    top: 47%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 28px;
    color: #fff; /* Change text color as needed */
    margin: 20px;
  }

  .btn {
    background-color: #208771; 
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px; 
    padding: 10px 20px; 
    border: none; 
    border-radius: 5px; 
    cursor: pointer; 
    transition: background-color 0.3s, color 0.3s;
  }
  .btn:hover{
    background-color: #186c5d; 
  }
  .btn:active{
    background-color: #145c4f;
  }

  .btn-alt {
    background-color: rgba(2,18,15,.9);
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px; 
    padding: 10px 20px; 
    border: none; 
    border-radius: 5px; 
    cursor: pointer; 
    transition: background-color 0.3s, color 0.3s;
  }
  .btn-alt:hover{
    background-color: #186c5d; 
  }
  .btn-alt:active{
    background-color: #145c4f;
  }
  .align-left{
    text-align: left;
    letter-spacing: -1px; /* You can adjust the value as needed */
  }
  .align-right{
    float: right;
    position: relative;
    top: -300px;
  }
  .css-bnr459-UppercaseOneLiner {
    color: #D5D0D7;
    font-family: 'Inter',sans-serif;
    font-weight: 400;
    font-size: clamp( 1rem * 0.8, 1vw, 1rem * 0.9 );
    text-transform: uppercase;
    font-weight: 600;
    text-align: left;
}
.img-nyse{
  float: left;
  height: 50px;
  padding: 3px;
}
