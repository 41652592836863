/* TextBoxOverlay.css */
.text-box-overlay {
    background-color: rgba(2, 18, 15, 0.9); /* White background with 70% opacity 02120F*/
    /* padding: 10%; */
    padding-left:5%;
    padding-bottom:5%;
    padding-right:5%;
    padding-top:2%;
    border-radius: 5%;
    max-width: 150%; /* Adjust as needed */
    position: static;
    height: auto;
    /*margin: 20px auto;  Center the box */
    text-align: center; /* Center the text */

  }
