@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
/*
@media (max-width: 768px) {
  /* Styles for screens with a width of 768px or less 
}
*/
/*
@media (min-width: 769px) and (max-width: 1024px) {
   Styles for screens between 769px and 1024px wide 
}
*/
.navbar {
    display: flex;
    height: 60px;
    justify-content: space-between;
    align-items: center;
    background-color: #02120F;
    padding: 0px 30px;
    color: white;
    margin-top: 0;
    position: sticky;
    top: 0;
    z-index: 1000;
    font-family: 'Montserrat', sans-serif;
  }
  .logo-image {
    height: 55px; /* Adjust the size as needed */
    margin-left: 5px; /* Add some space between the text and the image */
    width: auto;
    vertical-align: middle; /* Align the image with the text */
  }
  /*.logo a {
    font-size: 32px;
    /*font-family: 'Lato', sans-serif;
    text-decoration: none;
    color: white;
    position: relative;
    top: 14px;
 /*   border: 2px solid white; /* Border width and color */
    /*padding: 5px 10px; /* Padding inside the border */
    /*display: inline-block; /* Allows padding and border to be applied */
    /*transition: 0.3s; /* Optional: Smooth transition effect on hover */
    /*border-radius: 4px;
  }
*/


  /* Optional: Change border color on hover */
  .logo a:hover {
    border-color: #f9a825; /* Change to your preferred hover color */
  }
  
  .menu {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    background-color: #02120F;
  }
  
  .menu li {
    margin: 0 15px;
  }
  
  .menu a {
    text-decoration: none;
    color: white;
    transition: color 0.3s;
  }
  
  .menu a:hover {
    color: #00886a;
  }
  