:root{
    --info_width: 350px

}
.gradient-img{
    background-image: url('./images/darker_gradient_desktop.png');
    min-height: 100vh;
    background-size: cover; /* Scale the background image to cover the entire container */
    background-repeat: no-repeat; /* Prevent the background image from repeating */
    background-position: center; /* Center the background image */
    
  }
  .product-info{
    position: absolute;
    top: 68%;
    left: 46.1%;
    font-family: 'Montserrat', sans-serif;
    color: #fff;
    
  }
  .product-line{

    margin-top: 35px;
    width: 100%;
    margin-bottom: 25px;
  }
  .product-header{
    position: relative;
    left: -23%;

  }
  .product-info-flex{
    position: relative;
    display: flex;
    justify-content: center;

    max-width: 230px;


  }
.heading-1{
    margin-right: 70px;

}
.heading-2{
  margin-right: 70px;

}

  .stock-image{
    margin-top: 25px;
    margin-bottom: 25px;
  }
    /*
  .heading-mid{
    position: absolute;
    top: 70%;
    left: 45%;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    text-align: left;
    max-width: var(--info_width);
  }
  .text-stocks-top{
    margin-bottom: 8px;
    letter-spacing: -1px; /* You can adjust the value as needed 
  }
  .text-stocks{
    margin-top: 8px;
  }
  .heading-left{
    position: absolute;
    top: 70%;
    left: 25%;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    text-align: left;
    max-width: var(--info_width);
    min-width: var(--info_width);
  }
  */